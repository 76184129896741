<template>
    <v-dialog
        v-model="dialog"
        width="550"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        persistent
        class="dialog-style"
    >
      <v-card style="overflow-y:auto;">
        <v-card-title class="primary2" style="height: 30px">
            <span class="mx-2 mt-n4 chips-icon text-color-white"  style="font-size: 14px">  Assign     {{ this.$i18n.locale === 'en' ? userData.roleData.roleNameForDisplay : userData.roleData.roleNameForDisplayESP }} To Office</span>
            <v-spacer />
            <v-icon class="mt-n4" small color="white" @click="$emit('false')">mdi-window-close</v-icon>
        </v-card-title>
        <v-card class="mt-2 mx-2 overflow-hidden mb-2" elevation="5">
            <v-row>
                <v-col cols="12">
                  <Search class="ma-2" v-model="search"></Search>

<!--                  <v-data-table dense :items="hospitals" hide-default-footer  :no-data-text="$t('no-data-available')" :loading-text="$t('loading')">-->
<!--                    <template v-slot:item="{ item }">-->
<!--                      <tr class="my-0 py-0">-->
<!--                          <td style="height:10px !important;" class="text-left pointer px-0 mx-0 font-weight-medium" @click="selectedBranch(item)">{{item.hospitalName}}</td>-->
<!--                      </tr>-->
<!--                    </template>-->
<!--                  </v-data-table>-->
                  <v-data-table
                      :key="tableKey"
                      outlined
                      dense
                      v-model="selectedHospitalIds"
                      :items="hospitals"
                      :no-data-text="$t('no-data-available')"
                      :loading-text="$t('loading')"
                      :height="pageHeight(-100)"
                      fixed-header
                      :headers="headers"
                      :loading="loading"
                      show-select
                      :items-per-page="-1"
                      :search="search"
                  >
                    <template v-slot:footer.prepend>
                      <v-btn class="float-left" small color="primary" :loading="loadingButton" @click="selectedBranch">Save</v-btn>
                    </template>
                  </v-data-table>

<!--                    <v-simple-table dense>-->
<!--                        <template v-slot:default>-->
<!--                                <tr>-->
<!--                                    <th class="text-left">-->
<!--                                       {{$t('hospital')}}-->
<!--                                    </th>-->
<!--                                </tr>-->
<!--                                <tr-->
<!--                                    v-for="item in hospitals"-->
<!--                                    :key="item.id"-->
<!--                                    @click="selectedBranch(item)"-->
<!--                                    style="cursor:pointer;"-->
<!--                                    >-->
<!--                                    <td class="text-left">{{ item.hospitalName }}</td>-->
<!--                                </tr>-->
<!--                        </template>-->
<!--                      </v-simple-table>-->
                </v-col>
            </v-row>
        </v-card>
      </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Search from '@/views/Client/vuetify-components/Search.vue';

export default {
	props: ['dialog', 'specializations', 'staffUser'],
	components: {
    Search,
		// BirthdatePicker,
	},
	data () {
		return {
			loader: null,
			loading: false,
			valid: false,
			title: null,
      selectedHospitalIds: [],
      tableKey: 0,
      loadingButton: false,
      search: '',
    };
	},
	computed: {
		...mapState({
			userData: (state) => state.authentication.userData,
			hospitalsFromRepo: (state) => state.hospitals.hospitals,
		}),
    ...mapGetters({
      getUserById: 'users/getUserById',
      pageHeight: 'pageHeight',
    }),
    headers () {
      return [
        { text: this.$t('office-name'), value: 'hospitalName', class: '', align: 'start pl-4' },
      ];
    },
    userData () {
      return this.getUserById ? this.getUserById : null;
    },
		hospitals () {
			// we filter only those hospitals that are NOT already assigned, those thar are ssigned we are not showing as option
			var result = [];
			if (this.hospitalsFromRepo !== null) {
				for (let i = 0; i < this.hospitalsFromRepo.length; i++) {
					const hospitalIdToCheck = this.hospitalsFromRepo[i].id;
					var found = false;
					for (let j = 0; j < this.staffUser.hospitalsAssignedData.length; j++) {
						if (this.staffUser.hospitalsAssignedData[j].id === hospitalIdToCheck) {
							found = true;
							break;
						}
					}
					if (found === false) {
						result.push(this.hospitalsFromRepo[i]);
					}
				}
			}
			return result;
		},
	},
	async mounted () {
		await this.getHospitals();
	},
	methods: {
    async selectedBranch () {
      const results = []; // To collect results for all hospitals

      for (const hospital of this.selectedHospitalIds) {
        const body = { HospitalId: hospital.id, UserId: this.staffUser.id };
          const result = await this.$store.dispatch('hospitals/assignUserToHospital', body);
          result.hospitalName = hospital.hospitalName; // Attach hospitalName to the response
          this.loadingButton = true;
          results.push(result); // Collect the result
      }

      this.$emit('result', results); // Emit all results at once
    },
		async getHospitals () {
			await this.$store.dispatch('hospitals/getHospitals');
		},
	},
};
</script>
