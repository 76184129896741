<template>
  <div>
    <v-card
        v-if="selectedHospitalAdmin !== undefined && selectedHospitalAdmin !== null"
        class="mx-auto"
        elevation="0"
    >
      <v-card-title class="card-header fixed-card-title">
        <v-row class="justify-end d-flex" dense>
          {{$t('view-hospital-admin')}}
          <v-spacer></v-spacer>
          <v-icon @click="syncronizeDrawers" class="white-icon mr-2">mdi-pencil</v-icon>
          <v-icon @click="closeDrawer" class="white-icon mr-2">mdi-close</v-icon>
        </v-row>
      </v-card-title>
      <v-container class="mt-5">
        <v-card-text class="mt-8">
          <v-row class="pt-0" dense>
            <v-col cols="7" class="text-left px-0">
              <span class="view-staff">{{ selectedHospitalAdmin.firstName }} {{ selectedHospitalAdmin.lastName }}</span>
              <span class="darkgrey">{{ this.$i18n.locale === 'en' ? selectedHospitalAdmin.roleData.roleNameForDisplay : selectedHospitalAdmin.roleData.roleNameForDisplayESP }}</span>
              <v-col class="d-flex align-center pb-0 mb-0 pl-0">
                <v-icon small color="primary">mdi-email</v-icon>
                <span class="ml-2 color-primary">{{ selectedHospitalAdmin.email }}</span>
              </v-col>
              <v-col class="d-flex align-center py-0 my-0 pl-0">
                <v-icon small color="primary" class="mr-1">mdi-phone</v-icon>
                <span class="phone-primary"> {{ phone(selectedHospitalAdmin.phone) }}</span>
              </v-col>
            </v-col>
            <v-col cols="5" class="d-flex align-start justify-end px-0">
              <span class="mr-3" :style="selectedHospitalAdmin.status === 1 ? 'color: #52C41A; font-weight: bold' : 'color: gray; font-weight: bold;'" >
                <v-icon small :style="selectedHospitalAdmin.status === 1 ? 'color: #52C41A; font-weight: bold' : 'color: gray; font-weight: bold'" class="pb-1">mdi-checkbox-blank-circle</v-icon>
                {{ selectedHospitalAdmin.status === 1 ? $t('active-caps') : $t('inactive-caps')}}
              </span>
            </v-col>
          </v-row>
          <v-row class="my-4 pa-2 text-left data-table-outline" dense>
            <v-col class="text-left pb-2" cols="12">
              <span class="view-office-font">{{$t('Office')}}</span>
            </v-col>
            <v-col class="text-left py-0" cols="12">
              <span class="font-bold">{{$t('institution-name')}}: </span><span class="hospital-font">{{ selectedHospitalAdmin.hospitalAssignedData.medicalInstitutionData?.institutionName }}</span>
            </v-col>
            <v-col class="text-left py-0" cols="12">
              <span class="font-bold">{{$t('hospital-name')}}: </span><span class="hospital-font">{{ selectedHospitalAdmin.hospitalAssignedData.hospitalName }}</span>
            </v-col>
            <v-col class="text-left pt-0" cols="12">
              <span class="font-bold">{{$t('contact-person')}}:</span><span class="hospital-font">{{ selectedHospitalAdmin.hospitalAssignedData.contactPerson }}</span>
            </v-col>
            <v-col class="text-left pt-0" cols="12">
              <v-icon class="mr-1" small color="primary">mdi-email</v-icon><span class="hospital-font">{{ selectedHospitalAdmin.hospitalAssignedData.contactEmail }}</span>
            </v-col>
            <v-col class="text-left pt-0" cols="12">
              <v-icon class="mr-1" small color="primary">mdi-phone</v-icon><span class="hospital-font">{{ selectedHospitalAdmin.hospitalAssignedData.contactPhone }}</span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-container>
    </v-card>
    <v-card
        v-if="selectedOrganizationAdmin !== undefined && selectedOrganizationAdmin !== null"
        class="mx-auto"
        elevation="0"
    >
      <v-card-title class="card-header fixed-card-title">
        <v-row class="justify-end d-flex" dense>
          {{$t('view-organizational-admin')}}
          <v-spacer></v-spacer>
          <v-icon @click="syncronizeDrawers" class="white-icon mr-2">mdi-pencil</v-icon>
          <v-icon @click="closeDrawer" class="white-icon mr-2">mdi-close</v-icon>
        </v-row>
      </v-card-title>
      <v-container class="mt-5">
        <v-card-text class="mt-8">
          <v-row dense class="pt-0">
            <v-col cols="7" class="text-left px-0">
              <span class="view-staff">{{ selectedOrganizationAdmin.firstName }} {{ selectedOrganizationAdmin.lastName }}</span>
              <span class="darkgrey">{{ this.$i18n.locale === 'en' ? selectedOrganizationAdmin.roleData.roleNameForDisplay : selectedOrganizationAdmin.roleData.roleNameForDisplayESP }}</span>
              <v-col class="d-flex align-center pb-0 mb-0 pl-0" >
                <v-icon small color="primary">mdi-email</v-icon>
                <span class=" ml-2 color-primary">{{ selectedOrganizationAdmin.email }}</span>
              </v-col>
              <v-col class="d-flex align-center py-0 my-0 pl-0">
                <v-icon small color="primary" class="mr-1">mdi-phone</v-icon>
                <span class="phone-primary"> {{ phone(selectedOrganizationAdmin.phone) }}</span>
              </v-col>
            </v-col>
            <v-col cols="5" class="d-flex align-start justify-end px-0">
              <span class="mr-3" :style="selectedOrganizationAdmin.status === 1 ? 'color: #52C41A; font-weight: bold' : 'color: gray; font-weight: bold'" >
                <v-icon  small :style="selectedOrganizationAdmin.status === 1 ? 'color: #52C41A; font-weight: bold' : 'color: gray; font-weight: bold'" class="pb-1">mdi-checkbox-blank-circle</v-icon>
                {{ selectedOrganizationAdmin.status === 1 ? $t('active-caps') : $t('inactive-caps')}}
              </span>
            </v-col>
          </v-row>
          <v-row class="my-4 pa-2 text-left data-table-outline" dense>
            <v-col class="text-left pb-2" cols="12">
              <span class="view-office-font">{{$t('Organization')}}</span>
            </v-col>
            <v-col class="text-left py-0" cols="12">
              <span class="font-bold">{{$t('institution-name')}}: </span><span class="hospital-font">{{ selectedOrganizationAdmin.organizationAssignedData.institutionName }}</span>
            </v-col>
            <v-col class="text-left py-0" cols="12">
              <span class="font-bold">{{$t('contact-person')}}: </span><span class="hospital-font">{{ selectedOrganizationAdmin.organizationAssignedData.contactPerson }}</span>
              <v-col class="text-left pa-0">
                <v-icon small color="primary" class="mr-1">mdi-email</v-icon>
                <span class="color-primary">{{ selectedOrganizationAdmin.organizationAssignedData.contactEmail }}</span>
              </v-col>
              <v-col class="text-left pa-0 pb-1">
                <v-icon  small color="primary" class="mr-1">mdi-phone</v-icon>
                <span class="color-primary">{{ selectedOrganizationAdmin.organizationAssignedData.contactPhone }}</span>
              </v-col>
            </v-col>
          </v-row>
        </v-card-text>
      </v-container>
    </v-card>
    <v-card
        v-if="selectedCareGiver !== undefined && selectedCareGiver !== null"
        class="mx-auto"
        elevation="0"
    >
      <v-card-title >
        <v-row class="justify-end d-flex" dense>
          <v-col cols="2">
            <v-btn @click="syncronizeDrawers" outlined class="camel-case custom-outlined-color">
              <v-icon left>mdi-pencil</v-icon>
              <span style="text-transform: capitalize;">{{$t('edit')}}</span>
            </v-btn>
          </v-col>
          <v-col cols="2" class="text-right">
            <v-btn @click="closeDrawer" small height="36" outlined class="custom-outlined-color">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-container>
        <v-card-text>
          <v-row dense class="px-1">
            <v-col cols="3">
              <v-img :src="uploadPhoto"></v-img>
            </v-col>
            <v-col cols="7" class="text-left px-0">
              <span style="display: block; text-align: start; font-weight: bold; font-size: 20px">{{ selectedCareGiver.firstName }} {{ selectedCareGiver.lastName }}</span>
              <span style="display: block; text-align: start; color: #534d5d">{{ this.$i18n.locale === 'en' ? selectedCareGiver.roleData.roleNameForDisplay : selectedCareGiver.roleData.roleNameForDisplayESP }}</span>
              <v-col class="d-flex align-center pb-0 mb-0 pl-0">
                <v-icon small color="primary">mdi-email</v-icon>
                <span style="margin-left: 8px; color: #6600FF;">{{ selectedCareGiver.email }}</span>
              </v-col>
              <v-col class="d-flex align-center py-0 my-0 pl-0">
                <v-icon small color="primary" class="mr-1">mdi-phone</v-icon>
                <span style="text-align: start; color:#6600FF"> {{ phone(selectedCareGiver.phone) }}</span>
              </v-col>
            </v-col>
            <v-col cols="2" class="d-flex align-start justify-end px-0">
              <span :style="selectedCareGiver.status === 1 ? 'color: #52C41A; font-weight: bold' : 'color: gray; font-weight: bold; '" >
                <v-icon class="pb-1" small :style="selectedCareGiver.status === 1 ? 'color: #52C41A; font-weight: bold' : 'color: gray; font-weight: bold'">mdi-checkbox-blank-circle</v-icon>
                {{ selectedCareGiver.status === 1 ? $t('active-caps') : $t('inactive-caps')}}
              </span>
            </v-col>
          </v-row>
          <v-row class="my-div pa-2 mt-8 mx-0" dense>
            <v-col cols="12" class="text-left py-2">
              <span style="color: #534d5d; font-weight: bold; font-size: 20px">{{$t('hospital')}}</span>
            </v-col>
            <v-col cols="12" class="text-left py-0">
              <span style=" margin-top: 1px; font-weight: bold">{{ selectedCareGiver.hospitalAssignedData.medicalInstitutionData.institutionName }}</span>
            </v-col>
            <v-col cols="12" class="d-flex pt-0 align-center">
              <span style=" color: #534d5d">{{ selectedCareGiver.hospitalAssignedData.hospitalName }}</span>
            </v-col>
            <v-col class="text-left" cols="12">
              <span style=" font-weight: bold;">{{ selectedCareGiver.hospitalAssignedData.contactPerson }}</span>
              <v-col class="px-0 pt-1 pb-0 d-flex align-center">
                <v-icon small class="mr-2" color="primary" >mdi-email</v-icon>
                <span style="color:#6600FF">{{ selectedCareGiver.hospitalAssignedData.contactEmail }}</span>
              </v-col>
              <v-col class="pa-0 pb-2 d-flex align-center">
                <v-icon small class="mr-2" color="primary">mdi-phone</v-icon>
                <span style="color:#6600FF">{{ selectedCareGiver.hospitalAssignedData.contactPhone }}</span>
              </v-col>
            </v-col>
          </v-row>
        </v-card-text>
      </v-container>
    </v-card>
    <v-card
        v-if="selectedPartner !== undefined && selectedPartner !== null"
        class="mx-auto"
        elevation="0"
    >
      <v-card-title class="card-header fixed-card-title">
        <v-row class="justify-end d-flex" dense>
          {{$t('view-partner')}}
          <v-spacer></v-spacer>
          <v-icon @click="syncronizeDrawers" class="white-icon mr-2">mdi-pencil</v-icon>
          <v-icon @click="closeDrawer" class="white-icon mr-2">mdi-close</v-icon>
        </v-row>
      </v-card-title>
      <v-container class="mt-5">
        <v-card-text class="mt-8">
          <v-row class="pt-0" dense>
            <v-col cols="7" class="text-left px-0">
              <span class="view-staff">{{ selectedPartner.firstName }} {{ selectedPartner.lastName }}</span>
              <span class="darkgrey">{{ this.$i18n.locale === 'en' ? selectedPartner.roleData.roleNameForDisplay : selectedPartner.roleData.roleNameForDisplayESP }}</span>
              <v-col class="d-flex align-center pb-0 mb-0 pl-0">
                <v-icon small color="primary">mdi-email</v-icon>
                <span class="ml-2 color-primary">{{ selectedPartner.email }}</span>
              </v-col>
              <v-col class="d-flex align-center py-0 my-0 pl-0">
                <v-icon small color="primary" class="mr-1">mdi-phone</v-icon>
                <span class="phone-primary"> {{ phone(selectedPartner.phone) }}</span>
              </v-col>
            </v-col>
            <v-col cols="5" class="d-flex align-start justify-end px-0">
              <span class="mr-3" :style="selectedPartner.status === 1 ? 'color: #52C41A; font-weight: bold' : 'color: gray; font-weight: bold;'" >
                <v-icon small :style="selectedPartner.status === 1 ? 'color: #52C41A; font-weight: bold' : 'color: gray; font-weight: bold'" class="pb-1">mdi-checkbox-blank-circle</v-icon>
                {{ selectedPartner.status === 1 ? $t('active-caps') : $t('inactive-caps')}}
              </span>
            </v-col>
          </v-row>
<!--          <v-row class="my-4 pa-2 text-left data-table-outline" dense>-->
<!--            <v-col class="text-left pb-2" cols="12">-->
<!--              <span class="view-office-font">{{$t('Office')}}</span>-->
<!--            </v-col>-->
<!--            <v-col class="text-left py-0" cols="12">-->
<!--              <span class="font-bold">{{$t('institution-name')}}: </span><span class="hospital-font">{{ selectedPartner.hospitalAssignedData.medicalInstitutionData?.institutionName }}</span>-->
<!--            </v-col>-->
<!--            <v-col class="text-left py-0" cols="12">-->
<!--              <span class="font-bold">{{$t('hospital-name')}}: </span><span class="hospital-font">{{ selectedPartner.hospitalAssignedData.hospitalName }}</span>-->
<!--            </v-col>-->
<!--            <v-col class="text-left pt-0" cols="12">-->
<!--              <span class="font-bold">{{$t('contact-person')}}:</span><span class="hospital-font">{{ selectedPartner.hospitalAssignedData.contactPerson }}</span>-->
<!--            </v-col>-->
<!--          </v-row>-->
        </v-card-text>
      </v-container>
    </v-card>
    <v-card
        v-if="selectedApiUser !== undefined && selectedApiUser !== null"
        class="mx-auto"
        elevation="0"
    >
      <v-card-title class="card-header fixed-card-title">
        <v-row class="justify-end d-flex" dense>
          {{$t('view-api-user')}}
          <v-spacer></v-spacer>
          <v-icon @click="syncronizeDrawers" class="white-icon mr-2">mdi-pencil</v-icon>
          <v-icon @click="closeDrawer" class="white-icon mr-2">mdi-close</v-icon>
        </v-row>
      </v-card-title>
      <v-container class="mt-5">
        <v-card-text class="mt-8">
          <v-row class="pt-0" dense>
            <v-col cols="7" class="text-left px-0">
              <span class="view-staff">{{ selectedApiUser.firstName }} {{ selectedApiUser.lastName }}</span>
              <span class="darkgrey">{{ this.$i18n.locale === 'en' ? selectedApiUser.roleData.roleNameForDisplay : selectedApiUser.roleData.roleNameForDisplayESP }}</span>
              <v-col class="d-flex align-center pb-0 mb-0 pl-0">
                <v-icon small color="primary">mdi-email</v-icon>
                <span class="ml-2 color-primary">{{ selectedApiUser.email }}</span>
              </v-col>
              <v-col class="d-flex align-center py-0 my-0 pl-0">
                <v-icon small color="primary" class="mr-1">mdi-phone</v-icon>
                <span class="phone-primary"> {{ phone(selectedApiUser.phone) }}</span>
              </v-col>
            </v-col>
            <v-col cols="5" class="d-flex align-start justify-end px-0">
              <span class="mr-3" :style="selectedApiUser.status === 1 ? 'color: #52C41A; font-weight: bold' : 'color: gray; font-weight: bold;'" >
                <v-icon small :style="selectedApiUser.status === 1 ? 'color: #52C41A; font-weight: bold' : 'color: gray; font-weight: bold'" class="pb-1">mdi-checkbox-blank-circle</v-icon>
                {{ selectedApiUser.status === 1 ? $t('active-caps') : $t('inactive-caps')}}
              </span>
            </v-col>
          </v-row>
          <!--          <v-row class="my-4 pa-2 text-left data-table-outline" dense>-->
          <!--            <v-col class="text-left pb-2" cols="12">-->
          <!--              <span class="view-office-font">{{$t('Office')}}</span>-->
          <!--            </v-col>-->
          <!--            <v-col class="text-left py-0" cols="12">-->
          <!--              <span class="font-bold">{{$t('institution-name')}}: </span><span class="hospital-font">{{ selectedPartner.hospitalAssignedData.medicalInstitutionData?.institutionName }}</span>-->
          <!--            </v-col>-->
          <!--            <v-col class="text-left py-0" cols="12">-->
          <!--              <span class="font-bold">{{$t('hospital-name')}}: </span><span class="hospital-font">{{ selectedPartner.hospitalAssignedData.hospitalName }}</span>-->
          <!--            </v-col>-->
          <!--            <v-col class="text-left pt-0" cols="12">-->
          <!--              <span class="font-bold">{{$t('contact-person')}}:</span><span class="hospital-font">{{ selectedPartner.hospitalAssignedData.contactPerson }}</span>-->
          <!--            </v-col>-->
          <!--          </v-row>-->
        </v-card-text>
      </v-container>
    </v-card>
  </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';
    import { mailPurple, phonePurple, hospitalBlack, uploadPhoto } from '@/assets';
    import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js';

    export default {
        name: 'HospitalViewDrawer',
        components: {
        },
        data: () => ({
            // valid: true,
            mailPurple: mailPurple,
            phonePurple: phonePurple,
            hospitalBlack: hospitalBlack,
            uploadPhoto: uploadPhoto,
            relatedHospitals: [],
            showHospitals: false,
        }),
        computed: {
          ...mapGetters({
            selectedHospital: 'hospitals/getSelectedHospital',
            selectedHospitalAdmin: 'users/getSelectedHospitalAdmin',
            selectedOrganizationAdmin: 'users/getSelectedOrganizationAdmin',
            selectedCareGiver: 'users/getSelectedCaregiver',
            selectedPartner: 'users/getSelectedPartner',
            selectedApiUser: 'users/getSelectedApiUser',
          }),
          ...mapState({
            allVerifiers: (state) => state.verifiers.allverifires,
          }),
        },
        methods: {
          phone (newVal) {
            if (newVal !== undefined) {
              if (!isValidPhoneNumber(newVal)) {
                this.phoneRules = [false];
              } else {
                this.phoneRules = [true];
              }
              return new AsYouType().input(newVal);
            }
          },
          closeDrawer () {
              this.$store.commit('users/SELECTED_HOSPITAL_ADMIN'); // set selectedHospitalAdmin = null
              this.$store.commit('users/SELECTED_ORGANIZATION_ADMIN'); // set selectedHospitalAdmin = null
              this.$store.commit('users/SELECTED_CARE_GIVER'); // set selectedCaregiver = null
              this.$store.commit('SET_EDIT_DETAILS_DRAWER', false);
          },
          syncronizeDrawers () {
              // open drawer for edit
              this.$store.commit('SET_UPDATE_DETAILS_DRAWER', {
                updateDrawer: true,
                page2: 'admins',
              });
          },
        },
    };
</script>
