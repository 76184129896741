<template>
  <v-card
      class="mx-auto"
      elevation="0"
  >
    <v-card-title class="card-header-add fixed-card-title">
      <v-row dense>
        <v-col class="d-flex align-center justify-start" cols="10">
          <v-icon small class="mr-4" :style="getUserById.status === 1 ? 'color: #09CB09' : ''">mdi-checkbox-blank-circle</v-icon>
           <span class="font-size15">{{ getUserById.status === 1 ? $t('active-caps') : $t('inactive-caps') }}</span>
        </v-col>
        <v-col cols="2" class="d-flex align-center justify-end">
          <v-icon @click="sinhronizeDrawers" color="white" class="mr-2">mdi-pencil</v-icon>
          <v-icon color="white" @click="closeEditDrowerDetails">mdi-close</v-icon>
        </v-col>
      </v-row>
    </v-card-title>
    <v-container class="mt-16">
      <v-row class="py-2 ">
        <v-col cols="12" class="text-left mx-0" >
          <div class="ml-3">
            <span class="d-flex font-weight-bold font-size18 mb-3">{{ userData.firstName }} {{ userData.lastName }}</span>
            <span class="font-size14">
              <img height="18px" :src="userData.roleId === 3 ? doctorBlack : nurseBlack" >
              {{ $i18n.locale === 'en' ? userData.roleData.roleNameForDisplay : userData.roleData.roleNameForDisplayESP }}
            </span>
            <span class="d-flex">
              <v-icon small color="primary">mdi-email</v-icon>
              <span class="color-primary font-size14 ml-1">{{ userData.email }}</span>
            </span>
            <span class="d-flex">
              <v-icon small color="primary" class="mr-1">mdi-phone</v-icon>
              <span class="color-primary font-size14"> {{ phone(userData.phone) }}</span>
            </span>
          </div>
        </v-col>
      </v-row>
      <v-card
          dense
          v-if="userData.roleId === 3 && userData.doctorMetadataReadDto.spetializationData.length > 0"
          class="mb-2"
          elevation="3"
      >
        <v-card-title class="font-weight-bold font-size16 pa-0 ml-5 mt-1">{{$t('specializations')}}</v-card-title>
        <v-col cols="12" class="mt-0 py-1 pl-4">
          <v-chip-group column v-if="userData?.doctorMetadataReadDto && this.$i18n.locale === 'en'">
            <v-chip small label color="primary" class="ma-1 mr-1 text-color-white"  v-for="spec in userData.doctorMetadataReadDto?.spetializationData" :key="spec.spetializationId">{{ spec.spetializationDesc }}
            </v-chip>
          </v-chip-group>
          <v-chip-group column  v-else>
            <v-chip small label color="primary" class="ma-0 mr-1 text-color-white" v-for="spec in userData.doctorMetadataReadDto?.spetializationData" :key="spec.spetializationId">{{ spec.spetializationDescEsp }}
            </v-chip>
          </v-chip-group>
        </v-col>
      </v-card>
      <div>

      </div>
<!--      DO not change the logic here, we need to have one hospital at least.-->
      <v-row v-if="userData.hospitalsAssignedData.length === 1" dense>
        <v-card class="elevation-3 pa-3 mx-1" width="500">
          <div class="d-flex align-center float-end flex-column"  v-if="roleName === 'ORGANIZATION_ADMIN'">
              <h5 class="font-size15 ml-10 mr-3">
                {{$t('assign')}}  {{ this.$i18n.locale === 'en' ? userData.roleData.roleNameForDisplay : userData.roleData.roleNameForDisplayESP }} {{$t('To')}} {{$t('office')}}
                <v-icon
                  elevation="0"
                  class="mt-n1"
                  color="primary"
                  @click="showDialogAssignHospitalToUser"
                  >
                mdi-plus
              </v-icon>
              </h5>
            <div class="d-flex align-center mt-9">
              <img class="staffView-office-contact-person-icon"  :src="PatientBlack" />
              <span class="small-font mt-1">
              {{$t('contact-person')}}:&nbsp;
                </span>
              <span class="small-font font-weight-medium mt-1">
                {{ userData.hospitalsAssignedData[0].contactPerson}}
              </span>
            </div>
          </div>
          <div class="text-left d-flex flex-column">
            <span class="small-font">{{$t("officeInfo")}}</span>
            <span class="small-font font-weight-medium mt-1">{{ userData.hospitalsAssignedData[0].hospitalName }}</span>
            <span class="small-font font-weight-medium mt-1">{{userData.hospitalsAssignedData[0].medicalInstitutionData.address}}</span>
            <span  class="small-font" v-if="userData.hospitalsAssignedData[0].contactPerson">
              <div class="mt-1">
            <v-icon color="primary" size="17">mdi-phone</v-icon>
            <span class="color-primary font-weight-medium mr-2 mt-1">{{userData.phone}}</span>
              </div>

            </span>
          </div>
        </v-card>
      </v-row>
      <v-card elevation="3" v-else dense class="my-5 mx-0">
        <v-col cols="12">
          <div class="d-flex align-center">
            <span class="font-weight-bold">{{$t('assigned-offices')}}</span>
            <v-btn
                v-if="roleName === 'ORGANIZATION_ADMIN'"
                small
                icon
                elevation="0"
                color="secondary"
                @click="showDialogAssignHospitalToUser"
            >
              <v-icon color="primary">mdi-plus</v-icon>
            </v-btn>
          </div>
        </v-col>
        <v-col class="px-0 py-0" cols="12">
          <v-simple-table dense :height="pageHeight(-30)" >
            <template v-slot:default>
              <tbody>
              <tr class="px-0 pb-0" v-for="assignedHospital in userData.hospitalsAssignedData" :key="assignedHospital.id">
                <td class="text-left font-bold">{{ assignedHospital.hospitalName }}</td>
                <td v-if="roleName === 'ORGANIZATION_ADMIN'" class="text-right">
                  <v-btn
                      v-if="roleName === 'ORGANIZATION_ADMIN'"
                      small
                      icon
                      elevation="0"
                      color="white"
                      @click="deAssignHospital(assignedHospital)"
                  >
                    <v-icon color="primary">mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-card>
    </v-container>
    <AssignHospitalToUserDialog
        v-if="assignHospitalDialog"
        :dialog="assignHospitalDialog"
        :staffUser="userData"
        @false="assignHospitalDialog = false"
        @close="closeAssignHospitalDialog"
        @result="resultBackAssignedHospital"
    ></AssignHospitalToUserDialog>
  </v-card>
</template>

<script>
import {
  patientBlack,
	doctorBlack,
	nurseBlack,
} from '@/assets';
import { mapGetters } from 'vuex';
import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js';
import { showSuccessAlert, showErrorAlert } from '@/utils/utilities';
import AssignHospitalToUserDialog from '@/views/dialogs/AssignHospitalToUserDialog';

export default {
	name: 'DashboardCoreAppBar',
	components: { AssignHospitalToUserDialog },
	data: () => ({
    PatientBlack: patientBlack,
		nurseBlack: nurseBlack,
		doctorBlack: doctorBlack,
		assignHospitalDialog: false,
	}),
	computed: {
		...mapGetters({
			getUserById: 'users/getUserById',
			roleName: 'authentication/getRole',
      pageHeight: 'pageHeight',
    }),
		userData () {
			return this.getUserById ? this.getUserById : null;
		},
		userRole () {
			return this.userData.roleData ? this.userData.roleData.roleNameForDisplay : '';
		},
		updateDrawer () {
			return this.$store.state.updateDrawer;
		},
	},
  methods: {
		async deAssignHospital (hospitalAssignedToDeassign) {
      var deassignInProgress = false;
      if (this.userData.hospitalsAssignedData.length < 2) {
        showErrorAlert(this.$t('there-must-be-at-least-one-facility-assigned'));
        return;
      }
      if (!deassignInProgress) {
        deassignInProgress = true;
        const body = { HospitalId: hospitalAssignedToDeassign.id, UserId: this.userData.id };
        await this.$store.dispatch('hospitals/deassignUserFromHospital', body)
            .then(async (res) => {
              if (res.resFlag) {
                showSuccessAlert(this.$t('success'));

                // remove from local array of assigned hospitals
                const indexOfHospitalToremove = this.userData.hospitalsAssignedData.findIndex(object => {
                  return object.id === hospitalAssignedToDeassign.id;
                });
                if (indexOfHospitalToremove > -1) {
                  this.userData.hospitalsAssignedData.splice(indexOfHospitalToremove, 1);
                }
              } else {
                showErrorAlert(this.$t('there-must-be-at-least-one-facility-assigned'));
              }
              deassignInProgress = false;
            });
      } else {
        showErrorAlert(this.$t('failed'));
      }
		},
		showDialogAssignHospitalToUser () {
			this.assignHospitalDialog = true;
		},
    resultBackAssignedHospital (res) {
      let successFlag = false; // To track if at least one operation succeeded

      res.forEach((response) => {
        if (response.resFlag) {
          successFlag = true; // Mark as success if any response has `resFlag` as true
          const newElement = { id: response.data.hospitalId, hospitalName: response.hospitalName };
          this.userData.hospitalsAssignedData.push(newElement);
        }
      });

      // Show alerts based on the overall result
      if (successFlag) {
        showSuccessAlert(this.$t('success')); // Show success alert only once
        this.closeAssignHospitalDialog(); // Close the dialog if there was a success
      } else {
        showErrorAlert(this.$t('failed')); // Show error alert if all operations failed
      }
    },
		closeAssignHospitalDialog () {
			this.assignHospitalDialog = false;
		},
		phone (newVal) {
			if (newVal !== null && newVal !== undefined) {
				if (!isValidPhoneNumber(newVal)) {
					this.phoneRules = [false];
				} else {
					this.phoneRules = [true];
				}
				return new AsYouType().input(newVal);
			} else return '';
		},
		closeEditDrowerDetails () {
			this.$store.commit('SET_EDIT_DETAILS_DRAWER', false);
		},
    sinhronizeDrawers () {
      this.$store.commit('SET_UPDATE_DETAILS_DRAWER', {
        updateDrawer: true,
        page2: 'medicalstaff',
      });
    },
	},
};
</script>
