<template>
  <v-card
      class="mx-auto"
      elevation="0">

    <v-card-title class="card-header fixed-card-title2">
      <v-row dense>
        <v-col cols="10" class="d-flex align-center justify-start">
          <v-toolbar-title >{{$t("View-Assessment")}}</v-toolbar-title>
        </v-col>
        <v-col cols="2" class="d-flex align-center justify-end">
          <v-icon left @click="sinhronizeDrawers" color="white" class="camel-case custom-outlined-color pr-1">mdi-pencil</v-icon>
          <v-icon color="white" height="36" @click="closeEditDrowerDetails"  class="custom-outlined-color ml-n2">mdi-close</v-icon>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <div class="mt-2 mb-3">
        <v-row>
        <v-col cols="9" class="text-left">
          <span class="font-weight-bold font-size14 color-black">{{ surveyData.templateName }}</span>
        </v-col>
        <v-col cols="3" class="text-right">
          <span  :style="surveyData.status ===1 ? 'color: #52C41A; font-weight: bold' : 'color: gray; font-weight: bold; font-size: 0.8em'" >
            <v-icon small :style="surveyData.status ===1 ? 'color: #52C41A; font-weight: bold' : 'color: gray; font-weight: bold'">mdi-checkbox-blank-circle</v-icon>
            {{ surveyData.status ===1 ? $t('active-caps') : $t('inactive-caps')}}
          </span>
        </v-col>
        </v-row>
      </div>
      <v-row>
        <v-col class="wellness-assessment-desc-border mx-2 rounded" >
          <span class="wellness-desc mb-2">{{$t('description')}}</span>
          <span class="wellness-detail-desc">{{surveyData.templateDescription}}</span>
        </v-col>
      </v-row>
      <v-card class="overflow-auto mt-3"  elevation="0" :height="testHeight">
        <v-row class="d-flex justify-center">
        </v-row>
        <div class="mt-10">
          <v-row class="mx-0" dense>
            <v-expansion-panels accordion>
              <v-expansion-panel class="" v-for="(question, index) in surveyById.questionsListWithAnswers" :key="index">
                <div v-if="index === 0" class="text-left ml-2 mt-5">
                 <span class="wellness-assessment-questions">{{ $t('questions') }}</span>
                </div>
                <v-expansion-panel-header>
                  <div class="font-weight-medium">
                    <v-avatar  size="26" max-width="20" color="chips" class="text-color-white mr-2">{{ index +1 }}</v-avatar>
                    <span class="line-height1-2"> {{ question.question }}</span>
                  </div>

                </v-expansion-panel-header>
                <v-expansion-panel-content class="text-left" v-for="(answer, answerIndex) in question.offeredAnswersList" :key="answerIndex">
                  <v-icon v-if="answer.possibleAnswer.trim() !== ''">mdi-chevron-right</v-icon>
                  <span class="font-weight-bold">
                    {{ answer.possibleAnswer }}
                  </span>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </div>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
export default {
	computed: {
		...mapState({
			verifiers: (state) => state.verifiers.verifiers,
			surveyById: (state) => state.mentalhealth.survey,
		}),
		surveyData () {
      return this.surveyById ? this.surveyById : {};
		},
		updateDrawer () {
			return this.$store.state.updateDrawer;
		},
    testHeight () {
      if (this.$vuetify.breakpoint.width <= 1280 && this.$vuetify.breakpoint.height <= 800) {
        return '535';
      }
      return '750';
    },
	},
	beforeDestroy () {
		this.$store.commit('SET_EDIT_DETAILS_DRAWER', false);
	},
	methods: {
		closeEditDrowerDetails () {
      this.$store.commit('mentalhealth/CLEAR_CURRENT_SURVEY_TEMPLATE', null);
			this.$store.commit('SET_EDIT_DETAILS_DRAWER', false);
		},
		sinhronizeDrawers () {
			this.$store.commit('SET_UPDATE_DETAILS_DRAWER', {
        updateDrawer: true,
        page2: 'wellnessassessments',
      });
		},
	},
};
</script>
